var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":_vm.dialogWidth,"scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t('app.buttons.edit'))+" "+_vm._s(_vm.$t('app.torneos.single'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"rounded":"","color":"primary"},on:{"click":_vm.saveItem}},[_vm._v(" "+_vm._s(_vm.$t('app.buttons.save'))+" ")])],1)],1),_c('v-card-text',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var reset = ref.reset;
return [_c('form',[_c('v-list',{attrs:{"three-line":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.sucursales.single')))]),_c('v-list-item-subtitle',[_c('validation-provider',{attrs:{"name":_vm.$t('app.sucursales.single'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"placeholder":_vm.$t('app.sucursales.single'),"items":_vm.sucursales,"item-text":"nombre","item-value":"id","error-messages":errors,"required":""},model:{value:(_vm.item.sucursal_id),callback:function ($$v) {_vm.$set(_vm.item, "sucursal_id", $$v)},expression:"item.sucursal_id"}})]}}],null,true)})],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.headers.name')))]),_c('v-list-item-subtitle',[_c('validation-provider',{attrs:{"name":_vm.$t('app.headers.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":_vm.$t('app.headers.name'),"error-messages":errors,"required":""},model:{value:(_vm.item.nombre),callback:function ($$v) {_vm.$set(_vm.item, "nombre", $$v)},expression:"item.nombre"}})]}}],null,true)})],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.headers.color')))]),_c('v-list-item-subtitle',[_c('validation-provider',{attrs:{"name":_vm.$t('app.headers.color'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-color-picker',{attrs:{"dot-size":"25","swatches-max-height":"200"},model:{value:(_vm.item.color),callback:function ($$v) {_vm.$set(_vm.item, "color", $$v)},expression:"item.color"}})]}}],null,true)})],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.headers.journey_quantity')))]),_c('v-list-item-subtitle',[_c('validation-provider',{attrs:{"name":_vm.$t('app.headers.journey_quantity'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":_vm.$t('app.headers.journey_quantity'),"error-messages":errors,"required":""},model:{value:(_vm.item.cantidad_jornadas),callback:function ($$v) {_vm.$set(_vm.item, "cantidad_jornadas", $$v)},expression:"item.cantidad_jornadas"}})]}}],null,true)})],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.headers.registration_fee')))]),_c('v-list-item-subtitle',[_c('validation-provider',{attrs:{"name":_vm.$t('app.headers.registration_fee'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":_vm.$t('app.headers.registration_fee'),"error-messages":errors,"required":""},model:{value:(_vm.item.costo_inscripcion),callback:function ($$v) {_vm.$set(_vm.item, "costo_inscripcion", $$v)},expression:"item.costo_inscripcion"}})]}}],null,true)})],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.headers.deposit')))]),_c('v-list-item-subtitle',[_c('validation-provider',{attrs:{"name":_vm.$t('app.headers.deposit'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":_vm.$t('app.headers.deposit'),"error-messages":errors,"required":""},model:{value:(_vm.item.fianza),callback:function ($$v) {_vm.$set(_vm.item, "fianza", $$v)},expression:"item.fianza"}})]}}],null,true)})],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.headers.start_date')))]),_c('v-list-item-subtitle',[_c('v-dialog',{ref:"datePickerModalStart",attrs:{"persistent":"","width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":_vm.$t('app.headers.start_date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","readonly":"","error-messages":errors,"required":""},model:{value:(_vm.item.fecha_inicio),callback:function ($$v) {_vm.$set(_vm.item, "fecha_inicio", $$v)},expression:"item.fecha_inicio"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.datePickerModalStart),callback:function ($$v) {_vm.datePickerModalStart=$$v},expression:"datePickerModalStart"}},[_c('v-date-picker',{attrs:{"locale":_vm.$store.state.lang},on:{"input":function($event){_vm.datePickerModalStart = false}},model:{value:(_vm.item.fecha_inicio),callback:function ($$v) {_vm.$set(_vm.item, "fecha_inicio", $$v)},expression:"item.fecha_inicio"}})],1)],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.headers.end_date')))]),_c('v-list-item-subtitle',[_c('v-dialog',{ref:"datePickerModalEnd",attrs:{"persistent":"","width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":_vm.$t('app.headers.end_date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","readonly":"","error-messages":errors,"required":""},model:{value:(_vm.item.fecha_fin),callback:function ($$v) {_vm.$set(_vm.item, "fecha_fin", $$v)},expression:"item.fecha_fin"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.datePickerModalEnd),callback:function ($$v) {_vm.datePickerModalEnd=$$v},expression:"datePickerModalEnd"}},[_c('v-date-picker',{attrs:{"locale":_vm.$store.state.lang},on:{"input":function($event){_vm.datePickerModalEnd = false}},model:{value:(_vm.item.fecha_fin),callback:function ($$v) {_vm.$set(_vm.item, "fecha_fin", $$v)},expression:"item.fecha_fin"}})],1)],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.headers.game_duration')))]),_c('v-list-item-subtitle',[_c('validation-provider',{attrs:{"name":_vm.$t('app.headers.game_duration'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":_vm.$t('app.headers.game_duration'),"error-messages":errors,"required":"","suffix":"min"},model:{value:(_vm.item.duracion_juego),callback:function ($$v) {_vm.$set(_vm.item, "duracion_juego", $$v)},expression:"item.duracion_juego"}})]}}],null,true)})],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.headers.active')))]),_c('v-list-item-subtitle',[_c('v-switch',{staticClass:"pl-5",attrs:{"placeholder":_vm.$t('app.headers.active'),"label":_vm.item.activo ? _vm.$t('app.general.yes') : _vm.$t('app.general.no')},model:{value:(_vm.item.activo),callback:function ($$v) {_vm.$set(_vm.item, "activo", $$v)},expression:"item.activo"}})],1)],1)],1),(_vm.equipos.length > 0)?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('app.equipos.name'))+" "),_c('v-btn',{attrs:{"small":"","outlined":""},on:{"click":function($event){return _vm.item.equipos.push({equipo_id: null, hora_preferente: null})}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus-circle ")]),_vm._v(" "+_vm._s(_vm.$t('app.buttons.add'))+" ")],1)],1),_c('v-list-item-subtitle',_vm._l((_vm.item.equipos),function(equippo,index){return _c('v-row',{key:("equipo-" + index)},[_c('v-col',{staticClass:"d-flex flex-grow-1 flex-shrink-0"},[_c('validation-provider',{attrs:{"name":_vm.$t('app.equipos.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"disabled":_vm.equipos.length === 0,"placeholder":_vm.$t('app.equipos.name'),"items":_vm.equipos,"item-text":"nombre","item-value":"id","error-messages":errors,"required":""},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-avatar',[_c('img',{attrs:{"src":data.item.logotipo}})]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(data.item.nombre)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(data.item.sucursal.nombre)}})],1)]}}],null,true),model:{value:(_vm.item.equipos[index].equipo_id),callback:function ($$v) {_vm.$set(_vm.item.equipos[index], "equipo_id", $$v)},expression:"item.equipos[index].equipo_id"}})]}}],null,true)})],1),_c('v-col',{staticClass:"d-flex flex-grow-1 flex-shrink-0"},[_c('v-dialog',{ref:"timePickerModal",refInFor:true,attrs:{"return-value":_vm.item,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.item=$event},"update:return-value":function($event){_vm.item=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":_vm.$t('app.headers.description'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('app.headers.time'),"prepend-icon":"mdi-clock-outline","readonly":"","error-messages":errors,"required":""},model:{value:(_vm.item.equipos[index].hora_preferente),callback:function ($$v) {_vm.$set(_vm.item.equipos[index], "hora_preferente", $$v)},expression:"item.equipos[index].hora_preferente"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.timePickerModal),callback:function ($$v) {_vm.timePickerModal=$$v},expression:"timePickerModal"}},[(_vm.timePickerModal)?_c('v-time-picker',{attrs:{"full-width":""},model:{value:(_vm.item.equipos[index].hora_preferente),callback:function ($$v) {_vm.$set(_vm.item.equipos[index], "hora_preferente", $$v)},expression:"item.equipos[index].hora_preferente"}},[_c('v-col',{staticClass:"d-flex justify-center"},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.timePickerModal = false}}},[_vm._v(" "+_vm._s(_vm.$t('app.buttons.cancel'))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.timePickerModal = false}}},[_vm._v(" "+_vm._s(_vm.$t('app.buttons.ok'))+" ")])],1)],1):_vm._e()],1)],1),_c('v-col',{staticClass:"d-flex flex-shrink-1 flex-grow-0"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.item.equipos.splice(index, 1)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)],1)],1)}),1)],1)],1):_vm._e()],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }